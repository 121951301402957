* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}


.Jankovci {
    font-family: 'Signatie', sans-serif;
    font-weight: normal;
    font-size: 3em;
    text-align: center;
    padding: 0;
    margin: 0;
    color: white;
}

nav ul {
    list-style-type: none;
    margin: auto;
    padding: 1%;
    display: inline-block;
    text-align: center;
    
}
nav ul li a {
    cursor: pointer;
    text-decoration: none;
    color: rgb(255, 255, 255);
    color: rgba(255, 255, 255, 0.747);
}

nav ul li a:hover {
    color: #97b8bb;
    text-transform: uppercase;
}

nav {
    text-align: center;
}

.NavBckg {
   background: #05454B;
}

.flag {
    padding: 1%;
    cursor: pointer;
}

@media only screen and (max-width: 512px) {
    .Jankovci {
        font-size: 2.2em;
    }
}