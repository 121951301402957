.containerGalery {
    width: 100%;
    display: inline-block;
}

.imgGalery {
    width: 33.33%;
}

@media only screen and (max-width: 768px) {
    .imgGalery {
        width: 50%;
    }
 }
 
 @media only screen and (max-width: 480px) {
    .imgGalery {
        width: 100%;
    }
 }
 