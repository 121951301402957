.dbTable  td, .dbTable th, .dbTable tr .tr-header .css-19evqs0-getHeaderRowContainerStyle-HeaderRow {
    text-align: left !important;
    width: 14.29%;
    padding: 2%;
}

.dbTable .header, .dbTable .order {
    font-weight: bold;
}
.dbTable div {
    width: 100%;
    padding: 2%;
}
.dbTable tr:nth-child(2n + 1),  .dbTable div:nth-child(2n) {
    background: #cddadb !important;
}

.dbTable tr:nth-child(2n + 1),  .dbTable div:nth-child(2n + 1) {
    background: #cddadb !important;
}
.dbTable {
    width: 66.66%;
    margin: auto;
}
.dbHeading {
    text-align: center;
    background: #05454B;
    color: white;
    text-transform: uppercase; 
    padding: 0.5%;
    margin-top: 2%;
    margin-bottom: 2%;
}

@media only screen and (max-width: 600px) {
    .dbTable {
        width: 100%;
    }
    .dbTable td, .dbTable th {
        font-size: .7em;    
    }
    .container-btn {
      font-size: 1.5em;
    }
 }
 