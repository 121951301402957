.LoadingTitle {
    font-size: 2em;
    color: #05454B;
    text-align: center;
    margin: auto;
    margin-top: 15%;
}
.circle{
    width: 180px;
    height: 180px;
    display: block;
    position: fixed;
    border: 10px inset #05454B;
    top: 50%;
    left: 50%;
    margin-left: -100px;
    margin-top: -100px;
    border-radius: 200px;
    -moz-animation: rotate 5s infinitelinear;
    -webkit-animation: rotate 5s infinite linear;
    animation: rotate 5s infinite linear;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
  }
  
  .circle-small{
    width: 150px;
    height: 150px;
    display: block;
    position: fixed;
    border: 6px outset #05454B;
    top: 50%;
    left: 50%;
    margin-left: -81px;
    margin-top: -81px;
    border-radius: 156px;
    -moz-animation: rotate-rev 3s infinite linear;
    -webkit-animation: rotate-rev 3s infinite linear;
    animation: rotate-rev 3s infinite linear;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
  }
  
  .circle-big{
    width: 210px;
    height: 210px;
    display: block;
    position: fixed;
    border: 4px dotted #05454B;
    top: 50%;
    left: 50%;
    margin-left: -109px;
    margin-top: -109px;
    border-radius: 214px;
    -moz-animation: rotate-rev 10s infinite linear;
    -webkit-animation: rotate-rev 10s infinite linear;
    animation: rotate-rev 10s infinite linear;
  }
  
  .circle-inner{
    width: 80px;
    height: 80px;
    background: #05454B;
    display: block;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -40px;
    margin-top: -40px;
    border-radius: 80px;
    -moz-animation: pulse 1.5s infinite ease-in;
    -webkit-animation: pulse 1.5s infinite ease-in;
    animation: pulse 1.5s infinite ease-in;
    opacity: 1;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
  }
  
  .circle-inner-inner{
    width: 100px;
    height: 100px;
    display: block;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    margin-top: -50px;
    border-radius: 100px;
    -moz-animation: pulse 1.5s infinite ease-in;
    -webkit-animation: pulse 1.5s infinite ease-in;
    animation: pulse 1.5s infinite ease-in;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
  }
  
  @-moz-keyframes rotate{
    0% {-moz-transform: rotate(0deg);}
    100% {-moz-transform: rotate(360deg);}
    }
    
    @-webkit-keyframes rotate{
    0% {-webkit-transform: rotate(0deg);}
    100% {-webkit-transform: rotate(360deg);}
    }
    
    @keyframes rotate{
    0% {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
    }
    
    /*==============ROTATE-REV=================*/
    
    @-moz-keyframes rotate-rev{
    0% {-moz-transform: rotate(0deg);}
    100% {-moz-transform: rotate(-360deg);}
    }
    
    @-webkit-keyframes rotate-rev{
    0% {-webkit-transform: rotate(0deg);}
    100% {-webkit-transform: rotate(-360deg);}
    }
    
    @keyframes rotate-rev{
    0% {transform: rotate(0deg);}
    100% {transform: rotate(-360deg);}
    }
    
    /*==============PULSE======================*/
    
    @-moz-keyframes pulse{
    0% {
          -moz-transform: scale(0.1);
          opacity: 0.2;
      }
    50% {
          -moz-transform: scale(1);
          opacity: 0.8;
      }
      100% {
          -moz-transform: scale(0.1);
          opacity: 0.2;
      }
    }
    
    @-webkit-keyframes pulse{
    0% {
          -webkit-transform: scale(0.1);
          opacity: 0.2;
      }
    50% {
          -webkit-transform: scale(1);
          opacity: 0.8;
      }
      100% {
          -webkit-transform: scale(0.1);
          opacity: 0.2;
      }
    }
    
    @keyframes pulse{
    0% {
          transform: scale(0.1);
          opacity: 0.2;
      }
    50% {
          transform: scale(1);
          opacity: 0.8;
      }
    100% {
          transform: scale(0.1);
          opacity: 0.2;
      }
    }
      