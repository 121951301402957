.guestList p {
    display: inline-block;
    margin-left: 2%;
    width: 85%;
}

.guestList {
    display: block;
    margin: auto;
    margin-top: 1%;
    width: 66.66%;
    text-align: left;
}

.deleteIcon {
    color: #e06e00;
    vertical-align: middle;
}
.deleteIcon:hover {
    cursor: pointer;
}

.mainHeading {
    text-transform: uppercase;
    text-align: center;
    color: #05454B;
    margin-top: 3%;
    margin-bottom: 2%;
}

#GuestsForm {
    width: 66.66%;
    margin: auto;
    text-align: center;
}

#GuestsForm button {
    padding: 2%;
    border: 1px solid #05454B;
    background-color: #05454B;
    color: white;
    text-transform: uppercase;
    font-size: 1em;
    margin-bottom: 4%;
}

#GuestsForm button:hover {
    cursor: pointer;
}

.formInput {
    border: none;
    background: #05454b33;
    width: 40%;
    padding: 2%;
    font-size: 1em;
}

.formInput:focus {
    border: 1px solid #05454b33;
    border-radius: 0px;
}
.formInput::placeholder {
    color: #05454B;
}

.popupBtn {
    background: none;
    border: none;
}