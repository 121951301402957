.Bac {
    overflow: scroll;
    min-height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    background-image: url('../../components/media/bckg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -300;
    width: 100%;
    text-align: center;

}
.content {
    background-color: white;
    background-color: rgba(255, 255, 255, 0.4);
    width: 33.33%;
    height: 100%;
    margin: auto;
    padding: 2%;
    margin-top: 2%;
}

.mainPageText {
    font-size: 2.5em;
    color: #05454b;
    margin-bottom: 5%;
}

.author {
    color: black;
}

.counter p{
 background-color: #05454B;
 color: white;
 font-size: 2em;
 margin: auto;  
}
.counter span {
    display: inline-block;
    margin-right: 2%;
    width: 23%;
    text-align: center;
}

.counter h3 {
    font-size: .8em;
    margin: auto;
    color: #05454B;
    margin-bottom: 0;
}
.counter {
    width: 90%;
}


@media only screen and (max-width: 768px) {
    .content {
      width: 80%;
    }
}

@media only screen and (max-width: 576px) {
    .content {
      width: 100%;
      margin-top: 5%;
      padding: 10%;
    }
}