.mainHeading {
    text-transform: uppercase;
    text-align: center;
    color: #05454B;
}

.hide {
    display: none;
}

.show {
    display: block;
    width: 66.66%;
    margin: auto;
}

.searchBar {
    width: 66.66%;
    margin: auto;
    margin-top: 2%;
    margin-bottom: 1%;
}

.searchBar input {
    margin-left: 1%;
    margin-bottom: 1%;
    background: white;
    border: 3px solid #05454b;
    padding: 1.5%;
}

.searchBar input::placeholder {
    color: #05454b;
}

.btnAdd {
    display: inline-block;
    margin: auto;
    text-align: center;
    color: white;
    z-index: 50;
    background: #05454B;
    border: none;
    border-radius: 2em;
    padding: 0.7% 1.5% .7% 1.5%;
    width: 20%;
    font-size: 1em;
    cursor: pointer;
}
.PageTop {
    width: 66.66%;
    text-align: center;
    margin: auto;
    margin-top: 3%;
    margin-bottom: 2%;
}
.fieldsetArea {
    width: 66.66%;
    margin: auto;
    text-align: center;
}
.fieldsetArea .pContainer {
    margin: auto;
    width: 90%;
    text-align: center;
}
.fieldsetArea p {
    display: inline-block;
    width: 30%;
    text-align: left;
}
.fieldsetArea p input {
    display: block;
    margin-bottom: 2%;
    margin-top: 2%;
}
.fieldsetArea .css-b62m3t-container {
    width: 90% !important;
    margin: auto !important;
    margin-bottom: 2% !important;
}
.PageTop .mainHeading {
    width: 25%;
    display: inline-block;
}

@media only screen and (max-width: 485px) {
    .show {
     width: 90%;
    }
 }
 
  
