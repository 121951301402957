* {
    box-sizing: border-box;
}

.Bac {
    overflow: scroll;
    min-height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    background-image: url('../../components/media/bckg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -300;
    width: 100%;

}

.containerDivLogin  input:focus + .label-name .content-name,
.containerDivLogin input:valid + .label-name .content-name,
.containerDivLogin .input:focus + .label-name .content-name,
.containerDivLogin .input:valid + .label-name .content-name,
.emailValidLogin {
  bottom: 10px;
}

.Signup form {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}
.Login ul {
    margin-bottom: 0;
}
.Login-img {
    width: 10%;
    margin-left: 45.5%;
}

.iconInsideInput2 {
    display: inline;
    position: absolute;
    left: 83%;
    bottom: 1px;
    color: #05454B;
    cursor: pointer;
    z-index: 50;
  }
.Login-main ul li input[type=email] {
    margin-top: 15%;
    margin-bottom: 0;
  }
  .Login-main ul li input[type=password], .Login-main ul li input[type=text] {
    margin-top: 7%;
    margin-bottom: 0;
  }

  .Login-main {
      width: 30% !important; 
  }

  .Login-main input {
      border: none;
      border-bottom: 1px solid lightgrey;
      padding: 5%;
      background: white;
      border-radius: 10px;
      outline: none;
      margin-bottom: 7%;
      width: 90%;
      transition: .5s ease;
      margin-right: 2%;
      font-size: 1em;
      color: #05454B;
      position: relative;
    }
  
  .Login-main ul li {
    position: relative;
  }


.btn-div-l {
    text-align: center;
    padding-bottom: 5%;
    padding-top: 5%; 
}

.container-btn-login {
    position: relative;
    display: block;
    margin: auto;
    text-align: center;
    color: white;
    z-index: 50;
    background: #05454B;
    border: none;
    border-radius: 2em;
    padding: 1.5% 2% 1.5% 2%;
    width: 50%;
    font-size: 1.3em;
    cursor: pointer;
  }
  
.psswd-frgtn {
    border: none;
    color: white;
    background: none;
    font-size: .8em;
    font-weight: lighter;
    padding: 2%;
    cursor: pointer;
}

.Login-main .Signup-header input {
    float: left;
    width: 46%;
    padding: 2%;
    font-size: 1.4em;
}
.Login-main input:hover, .container-btn-login:hover {
    box-shadow: 0 3px 2px -2px #05454B;
    border-bottom: 1px solid #05454B;
}
.psswd-frgtn:hover {
    color: #dce7ff;

}
.Login-main input:focus {
  border-bottom: 1px solid #05454B;
}

.Login-main .button {
    width: 40%;
    margin: auto;
    display: inline-block;
}

@media only screen and (min-width: 1200px) {
    .Login-main h5 {
        margin-right: 0;
        font-size: 2.5em;
    }
    .containerDivLogin label {
        bottom: -6px;
        font-size: 1.3em;
    }
    .iconInsideInput2 {
        bottom: -2px;
        font-size: 1.8em;
    }
    .psswd-frgtn {
        font-size: 1em;
    }

}

@media only screen and (max-width: 1024px){ 
    .Login-main input {
        padding: 1%;
      }
    .iconInsideInput2 {
        bottom: 6px;
        font-size: .5em;
    }
}
   
@media only screen and (max-width: 767px) { 
    .Login-main {
        width: 100%;
        padding: 0 5%;
    }
    .Login-main input {
        font-size: .5em;
        padding: 2%;
      }
    .iconInsideInput2 {
        bottom: 4px;
        font-size: .5em;
        left: 77%;
    }
}

@media (orientation: portrait) {
    @media only screen and (max-width: 767px) { 
        .container-btn-login {
            width: 38%;
            padding: 3%;
          }
          .Login-main input {
            font-size: 1em;
            padding: 3%;
          }
          .Login-main ul li:first-child input {
            margin-top: 0;
         }
         .iconInsideInput2 {
            bottom: 4px;
            font-size: 0.9em;
            left: 77%;
        }
        .Login form {
            width: 70% !important;
        }
        .Login-img {
            width: 23%;
            margin-left: 38.5%;
            margin-top: 7%;
        }
        .Login-main input {
            font-size: 1em;
            padding: 6%;
        }
        .containerDiv label, .Check p, .Check label {
            font-size: 0.8em;
        }
        .Login-main ul li input[type=password], .Login-main ul li input[type=text]  {
            margin-top: 10%;
            margin-bottom: 3%;
        }
        .psswd-frgtn {
            margin-bottom: 3%;
        }
        .containerDivLogin  input:focus + .label-name .content-name,
        .containerDivLogin input:valid + .label-name .content-name,
        .containerDivLogin .input:focus + .label-name .content-name,
        .containerDivLogin .input:valid + .label-name .content-name,
        .emailValidLogin {
        bottom: 10px;
        }
        .Login button {
            width: 40%;
            
       }
    }
}
@media (orientation: landscape) {
    @media only screen and (max-width: 768px) { 
        .Login button {
            width: 37%;
            font-size: .5em;
        }
    }
    @media only screen and (max-width: 480px) { 
        .Login button {
            width: 63%;
            font-size: .4em;
        }
    }
}

  .Signup form {
    background-color: #97b6bb;
    background-color: #97b6bb99;
    width: 35%;
    margin: auto;
    border-radius: 25px;
    
  }
  
  .Signup ul {
    list-style: none;
  }

  .Login-main ul {
    padding-right: 4%;
    padding-left: 4%;
  }

  .Singup-main input {
      border: none;
      border-bottom: 1px solid lightgrey;
      padding: 2%;
      background: white;
      border-radius: 10px;
      outline: none;
      margin-bottom: 7%;
      width: 47%;
      transition: .5s ease;
      margin-right: 2%;
      font-size: 1em;
      color: #05454B;
      position: relative;
    }
  
  .Singup-main ul li {
    position: relative;
    
  }
  
  .containerDiv {
    position: relative;
    display: inline;
  }
  
  .containerDiv label {
    position: absolute;
    bottom: 0px;
    left: 4%;
    width: 100%;
    height: 100%;
    pointer-events: none;
    margin-top: 1%;
    font-size: .8em;
  }
  .containerDiv label::after {
    content: "";
    position: absolute;
    height: 50%;
    width: 82%;
    left: 5px;
    bottom: -4px;
    color: #05454B;
    font-size: .8em;
  
  }

  .content-name {
    position: absolute;
    bottom: 5px;
    left: 0px;
    transition: all 0.3s ease;
  }
  
  .containerDiv input:focus + .label-name .content-name,
  .containerDiv input:valid + .label-name .content-name,
  .containerDiv .input:focus + .label-name .content-name,
  .containerDiv .input:valid + .label-name .content-name,
  .emailValid,
  .dateValid {
    transform: translateY(-200%);
    -webkit-transform: translateY(-200%);
    -moz-transform: translateY(-200%);
    -o-transform: translateY(-200%);
    -ms-transform: translateY(-200%);
    color: white;
    font-size: .8em;
    bottom: 10px;
  }
  
  .Singup-main .Signup-header input {
      float: left;
      width: 46%;
      padding: 2%;
      font-size: 1.4em;
  }
  .Singup-main input:hover {
      box-shadow: 0 3px 2px -2px #05454B;
      border-bottom: 1px solid #05454B;
  }
  
  .Singup-main input:focus {
    border-bottom: 1px solid #05454B;
  }
  
  .Singup-main .button {
      width: 40%;
      margin: auto;
      display: inline-block;
  }
  

  .btn-image {
    width: 100%;
  }

  
    .iconInsideInput {
      display: inline;
      position: absolute;
      left: 80%;
      bottom: 1px;
      color: #05454B;
      cursor: pointer;
      z-index: 50;
    }
    
  /* Desktops, large screens */
    @media only screen and (max-width: 1024px){
     
      .Singup h2 {
          margin-top: 5%;
    
      }

      .Singup-main input {
        padding: 1%;
      }
      .containerDiv label, .Check p, .Check label {
      
        font-size: .6em;
      }
      .iconInsideInput {
        bottom: 6px;
        font-size: .5em;
    }
    .Signup form {
      width: 38%;
      padding-right: 1%;
      padding-left: 1%;
    
    }
    .Signup button {
      font-size: .8em;
    }
   
    }
  
    @media only screen and (max-width: 767px){
      .iconInsideInput {
        bottom: 4px;
        font-size: .5em;
        left: 77%;
    }
      .Singup-main {
        width: 100%;
        padding: 0 5%;
    }
      .content-name {
        font-size: 0.8em;
      } 
      .Check input[type='radio']:after {
        width: 8.5px;
        height: 8.5px;
        top: -4px;
        left: -2px;
    }
  
    .Check input[type='radio']:checked:after {
        width: 4px;
        height: 4px;
        top: -6px;
        left: -2px;
    }
    .Singup-main input {
      font-size: .5em;
      padding: 2%;
    }
  
    .containerDiv input:focus + .label-name .content-name,
  .containerDiv input:valid + .label-name .content-name,
  .containerDiv .input:focus + .label-name .content-name,
  .containerDiv .input:valid + .label-name .content-name,
  .emailValid,
  .dateValid { 
    font-size: .6em;
    bottom: 5px;
  }
    }
    
    @media (orientation: portrait) {
      @media only screen and (max-width: 767px) { 
        .Check input[type='radio']::after {
          width: 15px;
          height: 15px;
          
        }
        .iconInsideInput {
          bottom: 4px;
          font-size: 0.7em;
          left: 77%;
      }
        .Check input[type='radio']:checked:after {
          width: 12px;
          height: 12px;
        }
        .Check input[type='radio'] {
          top: -14px;
          left: 0px;
        }
        .Check {
          font-size: 1.6em;
        }
        .profile-Signup {
          width: 17%;
          margin-left: 42%;
      }
      .content-name {
        font-size: 1.5em;
        bottom: auto;
      } 
      .Singup-main input {
        font-size: 1em;
        padding: 3%;
      }
      .Signup button {
        font-size: 1em;
      } 
      .container-btn-signup {
        width: 38%;
        padding: 3%;
      }
      .btn-div {
        padding: 5%;
      }
    
      .Singup-main ul li:first-child input {
         margin-top: 0;
      }
      .Signup form {
        padding: 1%;
        width: 90%;
        padding-top: 5%;
      }
      .containerDiv input:focus + .label-name .content-name,
  .containerDiv input:valid + .label-name .content-name,
  .containerDiv .input:focus + .label-name .content-name,
  .containerDiv .input:valid + .label-name .content-name,
  .emailValid,
  .dateValid {
    font-size: 1em;
    bottom: 10px;
   }
  
      }
  
      @media only screen and (min-width: 481px) and (max-width: 768px) {
        @keyframes animateBarG {
          0% {transform: translateX(-250%);}
          100% {transform: translateX(0);}
      }
      .profile-Signup {
        width: 16%;
        margin-left: 42%;
        margin-top: 2%;
      }
      .Signup form {
        width: 66%;
      }
        .Signup h2 {
            margin-top: 7%;
            margin-bottom: 3%;
        }
      
        .Signup button {
          padding: 3%;
          width: 28%;
        }
        .centered-text-btn-signup {
          position: absolute;
          top: 50%;
          left: 47%;
          font-weight: bold;
          transform: translate(-50%, -50%);
        }
        
        .Signup .precontainer2 {
          display: block;
        }
      
        .signup-container:after {
         margin-bottom: 9%;
        }
        
      }
      
        .Signup .Signup-header {
          padding-top: 7%;
        }
        .Signup h2 {
          font-size: 2.6em;
        }
        
        @media only screen and (max-width: 688px) {
          .Signup-header {
            padding: 10%;
          }
          .Signup-logo {
            width: 15%;
            margin-top: 7%;
            margin-bottom: -10%;
          }
          .Signup h2 {
            font-size: 1.5em;
          }
        }
    }
    
    @media (orientation: landscape) {
      @media only screen and (max-width: 768px) {
        @keyframes animateBarG {
            0% {transform: translateX(-250%);}
            100% {transform: translateX(0);}
        }
      
    
        .Signup button {
          width: 40%;
          margin: auto;
          bottom: 250%;
        }
    
      }
    }