.css-26l3qy-menu {
  z-index: 100 !important;
}

.css-9gakcf-option {
    background-color: #05454B !important;
}

.css-9gakcf-option:hover, .css-1n7v3ny-option {
    background-color: #97b6bb !important;
}
.css-1pahdxg-conrol, .css-1pahdxg-conrol:hover, .css-1pahdxg-conrol:focus {
    border-color: #05454B !important;
    box-shadow: 0 0 0 1 #97b6bb !important;
}
.codeDiv {
    text-align: center;
}
.codeNum {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 2%;
}

.code {
    text-align: center;
    margin: auto;
    border: none;
    background:#97b6bb;
    width: 45%;
    font-size: 1.4em;
    color: #05454B;
    border-radius: 25px;
    margin-right: -15%;
    text-align: left;
    padding: 0.7% 1.5% .7% 1.5%;
}
.Login-main input:hover, .container-btn:hover {
    box-shadow: 0 3px 2px -2px #97b6bb !important;
    border-bottom: 1px solid #97b6bb !important;
}
.code:focus{
    outline: none;
}

.hidden {
    display: none;
}

.show {
    display: inline-block;
    width: 66.66%;
}

.showDiv {
    display: block;
}

.ucastDiv {
    text-align: left;
    width: 100%;
    display: inline-flex;
}
.ucastDiv p {
    display: inline-block;
    width: 25%;
}

.btn-color {
    background: #05454B !important;
}
.div-btn {
    display: inline !important;
}

.container-btn {
    position: relative;
    display: inline-block;
    margin: auto;
    text-align: center;
    color: white;
    z-index: 50;
    background: #05454B;
    border: none;
    border-radius: 2em;
    padding: 0.7% 1.5% .7% 1.5%;
    width: 15%;
    font-size: 1.3em;
    cursor: pointer;
  }
.css-yk16xz-control {
    border-color: white !important;
}
.codeForm {
    padding: 2%;
}
.ucastTable {
    width: 100%;
    padding: 2%;
}
.ucastTable tbody {
    display: grid;
}
.ucastTable:nth-child(2n + 1) {
    background:#719ca0;
}
.ucastTable:nth-child(2n + 2) {
    background:#97b6bb;
}
.ucastTable td, .ucastTable th {
    width: 66.66%;
    margin: auto;
    margin-top: 1%;
    margin-bottom: 1%;
}
.ucastTable td:first-child {
    display: grid;
}
.ucastDiv span {
    width: 50%;
}
.ucastTable tbody span {
    display: grid;
}
.ucastTable .tableHeading {
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 1%;
    margin-bottom: 1%;
}
.radio-toolbar input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
  }
  .radio-toolbar label {
    display: inline-block;
    background-color: white;
    color: #05454B;
    padding: 10px 20px;
    width: 50%;
    font-family: sans-serif, Arial;
    font-size: 16px;
    border-radius: 4px;
    margin-top: 2%;
}
.radio-toolbar input[type="radio"]:checked + label {
    background-color:#05454B;
    color: white;
}
.ucastTable .meno {
    font-size: 1.5em;
    font-weight: bold;
}
.pozvanka {
    width: 66.66%;
}
@media only screen and (max-width: 768px) {
    .pozvanka {
        width: 100%;
    }
    .show {
        width: 90%;
    }
    .ucastTable td, .ucastTable th {
        width: 90%;
    }
 }

@media only screen and (max-width: 485px) {

   .code {
       width: 80%;
   }
   .container-btn {
       width: 30%;
   }
   .show {
    width: 100%;
   }    
   .radio-toolbar label {
    font-size: .8em;
   }
   .codeNum {
    margin-top: 3%;
    margin-bottom: 5%;
   }
   .codeForm {
    margin-bottom: 5%;
   }
   .pozvanka {
    width: 90%;
   }
}

 